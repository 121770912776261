
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ExpectNextItem, Section, TextTemplate } from '../entities/text-template';
import { Injectable, SecurityContext } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TextTemplateService {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  textTemplate: TextTemplate | undefined;
  static readonly placeholder: string = "<Placeholder -> No text>";

  getLatestTextTemplate(): Observable<TextTemplate> {
    return this.http.get<TextTemplate>(`/api/texttemplate`);
  }

  async loadTexttemplate(): Promise<void> {
    this.textTemplate = await firstValueFrom(this.getLatestTextTemplate());
  }

  getTextSection(selector: string): Section | undefined {
    return this.textTemplate?.sections.find(x=> x.identifier === selector);
  }

  getSectionTitle(selector: string, minicodes?: Minicode[]): string {
    const section = this.getTextSection(selector);

    if (!section) return TextTemplateService.placeholder;

    return this.replaceMinicodes(section.title, minicodes);
  }

  getSectionInfo(selector: string, minicodes?: Minicode[]): string {
    const section = this.getTextSection(selector);

    if (!section) return TextTemplateService.placeholder;

    return this.replaceMinicodes(section.info, minicodes);
  }

  getSectionElementText(selector: string, elementSelector: string, minicodes?: Minicode[]): string {
    const section = this.getTextSection(selector);

    const element = section?.elements?.find(x=> x.identifier === elementSelector);

    if (!element) return TextTemplateService.placeholder;

    return this.replaceMinicodes(element.text, minicodes);
  }

  getSectionElementSubText(selector: string, elementSelector: string, minicodes?: Minicode[]): string {
    const section = this.getTextSection(selector);

    const element = section?.elements?.find(x=> x.identifier === elementSelector);

    if (!element) return TextTemplateService.placeholder;

    return this.replaceMinicodes(element.subText, minicodes);
  }

  getExpectNextList() : ExpectNextItem[]{
    const list = this.textTemplate?.expectNextList;
    if (!list) {
      throw new Error ("The ExpectNextList is not in the template.");
    }

    return list;
  }

  getBypassSanitizeSectionElementText(selector: string, elementSelector: string, minicodes?: Minicode[]): SafeHtml {
    const section = this.getTextSection(selector);

    const element = section?.elements?.find(x=> x.identifier === elementSelector);

    if (!element) return TextTemplateService.placeholder;

    return this.sanitizer.bypassSecurityTrustHtml(this.replaceMinicodes(element.text, minicodes));
  }

  private replaceMinicodes(text?: string,  minicodes?: Minicode[]): string {
    if (text === "") return text;
    if (!text) return TextTemplateService.placeholder;
    if (!minicodes) return text;

    let replacedText = text;
    for(const minicode of minicodes) {
      const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, minicode.value);
      replacedText = text.replace(minicode.code, sanitizedValue ?? "<error>");
    }

    return replacedText;
  }
}

export interface Minicode {
  code: string;
  value: string;
}